import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import {FacilitiesTable} from './facilities.table';
import {WaterFooter} from "../common/WaterFooter";
import {WaterHeader} from "../common/WaterHeader";
import {ModalSpinner} from "../common/ModalSpinner";
import {searchFacilitiesByZipCode} from '../service/ewg.data.service';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import {ZipInput} from '../searchZip/zip.input';


export function FacilitiesList( ) {

    const history = useHistory();
    const location = useLocation();
    const mockFacilities = require('../resources/mock.facilities.json');

    const [loading, setLoading] = useState(false);
    const [facilitiesList, setFacilitiesList] = useState();
    
    const queryStringParams = queryString.parse(location.search);
    const searchedZipCode = queryStringParams.zipCode;


    useEffect( () => {
        setLoading(true);

        searchFacilitiesByZipCode(searchedZipCode)
        .then( response => processSearchFacilitiesSuccess(response))
        .catch( error => processSearchFacilitiesError(error))

    }, [searchedZipCode]);


    function processSearchFacilitiesSuccess(response) {
        setLoading(false);

        if ( response && response.data ) {
            // TODO: remove this line once api is returning correct data
            // response.data = mockFacilities;
            // end TODO:
            setFacilitiesList(response.data.SystemList);
        }
    }

    function processSearchFacilitiesError(error) {
        setLoading(false);
        //console.log(error)
    }

    function goToContaminants(event, facility) {
        event.preventDefault();
        history.push(`/contaminantsList?pws=${facility.PWS}&zipCode=${searchedZipCode}`);    
    }

    return (
        <>
            <div className="container-fluid">
                <ModalSpinner showSpinner={loading}/>
                <div>
                    <WaterHeader></WaterHeader>
                </div>
                <div className="facilitiesListContainer">
                    <div className="facilitiesListInnerContainer">
                        <div>
                            <ZipInput/>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                        <div>
                            <h1 className="h1-dark-theme">Search Results: {searchedZipCode}</h1>
                        </div>

                        <p>Below are the results for utilities serving the searched zip code and the surrounding county.</p>
                        <FacilitiesTable facilitiesList={facilitiesList} zipCode={searchedZipCode} />    
                        {
                            facilitiesList && facilitiesList.length == 1 &&
                            <div>
                                <div>&nbsp;</div>
                                <div className="primary-btn" onClick={(event) => goToContaminants(event, facilitiesList[0])}>VIEW UTILITY</div>
                            </div>
                        }
                    </div>

                    
                </div>

                

                <div className="row">
                    <div className="col">
                        <WaterFooter showNowWhat={false}/>
                    </div>
                </div>
            </div>
        </>
    )

}