// var moment = require('moment-timezone');

// export function getEarliestAndLatestEffectiveDates(waterFacilityDataList) {

//     let allEffectiveDates = [];
//     if ( waterFacilityDataList && waterFacilityDataList.length > 0) {

//         waterFacilityDataList.forEach(waterFacilityData => {
//            if ( allEffectiveDates.indexOf(waterFacilityData.EffectiveDate) === -1 ) {
//                allEffectiveDates.push(waterFacilityData.EffectiveDate)
//            } 
//         });

//         allEffectiveDates.sort( (date1, date2) => {
//             let date1Moment = moment(date1);
//             let date2Moment = moment(date2);

//             if ( date1Moment.isBefore(date2Moment)) {
//                 return -1;
//             } else if ( date2Moment.isBefore(date1Moment) ) {
//                 return 1;
//             }
//             return 0;
//         });

//         return {
//             startEffectiveDate: allEffectiveDates[0],
//             endEffectiveDate: allEffectiveDates[ allEffectiveDates.length - 1]
//         };
        
//     }

//     return null;

// }


// function getUniqueContaminants(waterFacilityDataList) {
//     let uniqueContaminants = {};
//     if ( waterFacilityDataList && waterFacilityDataList.length > 0 ) {
//         waterFacilityDataList.forEach( (waterFacilityData) => {

//             if ( waterFacilityData.ParameterCode ) {
//                 if ( uniqueContaminants[ waterFacilityData.ParameterCode ] ) {
//                     uniqueContaminants[ waterFacilityData.ParameterCode ].push(waterFacilityData);
//                 } else {
//                     uniqueContaminants[ waterFacilityData.ParameterCode ] = [ waterFacilityData ];
//                 }    
//             }
            
//         });
//     }

//     return uniqueContaminants;
// }

// /**
//  * 
//  * @param {*} waterFacilityDataList 
//  * @returns {totalContaminants, exceedContaminants}
//  */
// export function calculateContaminantsInfo(waterFacilityDataList) {

    
//     let _totalContaminants = 0, _exceedContaminants = 0;

//     if ( waterFacilityDataList && waterFacilityDataList.length > 0 ) {
//        let uniqueContaminants = getUniqueContaminants(waterFacilityDataList);
        
//         // calculate the totals
//         Object.keys(uniqueContaminants).forEach( (eachContaminantKey) => {
            
//             let singleContaminantList = uniqueContaminants[ eachContaminantKey ]; 
            
//             let loadOverLimit2Present = false;
//             let pollutantLoad0Present = false;

//             if ( singleContaminantList && singleContaminantList.length > 0 ) {
//                 for (let iLoop = 0; iLoop < singleContaminantList.length; iLoop++) { 
                       
//                     let _LoadOverLimit2 = singleContaminantList[ iLoop ].LoadOverLimit2;
//                     let _PollutantLoad0 = singleContaminantList[ iLoop ].PollutantLoad0;   
//                     let _AllowableLoad = singleContaminantList[ iLoop ].AllowableLoad;
                    
//                     if ( !loadOverLimit2Present && !Number.isNaN(_LoadOverLimit2) &&
//                     !Number.isNaN(_AllowableLoad) && 
//                      ( _LoadOverLimit2 > 0 || _PollutantLoad0-_AllowableLoad > 0 )  ) {
//                         _exceedContaminants++;
//                         loadOverLimit2Present = true;

//                         // this property will make the contaminant appear in red color
//                         uniqueContaminants[ eachContaminantKey ].exceedsHeathGuideline = true;
//                     }
                
//                     if ( !pollutantLoad0Present && !Number.isNaN(_PollutantLoad0) && 
//                     _PollutantLoad0 > 0 ) {
//                         _totalContaminants++;
//                         pollutantLoad0Present = true;
//                         uniqueContaminants[ eachContaminantKey ].pollutantLoadPresent = true;
//                     }

//                 }
//             }

//         });

//         return {
//             totalContaminants: _totalContaminants,
//             exceedContaminants: _exceedContaminants,
//             uniqueContaminants
//         }

//     }

//     return null;

// }

function sortContaminants(c1, c2) {
    
    if ( c1 && c2 && 
        c1.ContaminantName && c2.ContaminantName ) {
            
            if ( c1.ContaminantName.toUpperCase() > c2.ContaminantName.toUpperCase()) {
                return 1;
            } else {
                return -1;
            }
    }
    return 0
}

/**
 * set few calculated fields on the response
 * 
 * @param {*} response 
 * @returns 
 */
export function enrichContaminantsByFacilityIdResponse( response ) {
    
    if ( response && response.data && response.data.ContaminantList ) {
        response.data.ContaminantList.forEach( (contaminant) => {
            if (contaminant.SystemAverage && contaminant.ContaminantHGValue &&
                contaminant.SystemAverage / contaminant.ContaminantHGValue >= 1 ) {
                contaminant.exceededLimit = true;
            } else {
                contaminant.exceededLimit = false;
            }
        });

        // sort the list in alphabatical order
        response.data.ContaminantList.sort(sortContaminants);
    }
    return response;
}

export function getContaminantsCount(contaminantsList) {
    return contaminantsList.length;
}

/**
 * if SystemAverage/ContaminantHGValue > 1, include in exceeded list
 * @param {*} contaminantsList 
 * @returns 
 */
export function getExceededContaminantsList(contaminantsList) {
    return contaminantsList.filter( contaminant => contaminant.exceededLimit)
}

/**
 * if SystemAverage/ContaminantHGValue < 1, include in otherContaminants list
 * @param {*} contaminantsList 
 */
export function getOtherContaminantsList(contaminantsList) {
    return contaminantsList.filter( contaminant => !contaminant.exceededLimit)
}

export function getExceededContaminantsCount(contaminantsList) {
    return getExceededContaminantsList(contaminantsList).length;
}