import React, {useState} from 'react';

export function ContaminantItem({ contaminantItemObj }) {

    const HIDDEN = "HIDDEN";
    const BLOCK = "BLOCK";
    const [detailDisplayMode, setDetailDisplayMode] = useState(HIDDEN);
    const MAX_CONTAMINANT_VALUE = getMaxContaminantValue (contaminantItemObj);

    function getMaxThreeDecimalPlaces(value) {
        // need only max of 3 decimal places
        let preciseValue = value.toPrecision(3);
        var truncValue = parseFloat(preciseValue);

        return truncValue;

    }

    function showDetailDisplayMode (event) {
        event.preventDefault();
        setDetailDisplayMode(BLOCK);
    }

    function hideDetailDisplayMode (event) {
        event.preventDefault();
        setDetailDisplayMode(HIDDEN);
    }

    function getMaxContaminantValue (contaminantItem) {

        let values = [];

        if (contaminantItem.SystemAverage)
            values.push(contaminantItem.SystemAverage);

        if (contaminantItem.ContaminantNationalAvg)
            values.push(contaminantItem.ContaminantNationalAvg);

        if (contaminantItem.ContaminantStateAvg)
            values.push(contaminantItem.ContaminantStateAvg);

        if (contaminantItem.ContaminantHGValue)
            values.push(contaminantItem.ContaminantHGValue);

        if (contaminantItem.ContaminantMCLValue)
            values.push(contaminantItem.ContaminantMCLValue);

        let maxValue = Math.max(...values);
        return maxValue;
    }

    function getContaminantBarWidth(contaminantValue) {
        let barWidthInFloat = ((contaminantValue / MAX_CONTAMINANT_VALUE) *.9)*100;
        let barWidth = Number(barWidthInFloat.toFixed(1)) + '%';
        return { width: barWidth };
    }

    /**
     * adjust the calculation based on ppm or ppb
     * 
     * @param {*} contaminantUnits 
     * @param {*} contaminantDisplayUnits 
     * @param {*} value 
     * @returns 
     */
    function adjustContaminantCalculation(contaminantUnits, contaminantDisplayUnits, value) {
        if ('ppb' === contaminantDisplayUnits &&
            'ppm' === contaminantUnits) {
            return `${getMaxThreeDecimalPlaces(value * 1000)} ppb`;
        } else if (contaminantUnits === contaminantDisplayUnits) {
            return `${getMaxThreeDecimalPlaces(value)} ${contaminantDisplayUnits}`;
        }
    }

    function getExceedFactor(systemAverage, contaminantHGValue) {
        return getMaxThreeDecimalPlaces(Number.parseFloat(systemAverage / contaminantHGValue));
    }

    function highLightCard(systemAverage, contaminantHGValue) {
        if ( (systemAverage / contaminantHGValue) >= 200 ) {
            return 'card-highLight';
        }
        return '';
    }

    function shouldShowLegalLimit(contaminantItem) {
        return (contaminantItem && contaminantItem.ContaminantMCLValue > 0);
    }

    function showLegalLimitBarChart(contaminantItem) {
        if ( shouldShowLegalLimit(contaminantItem) ) {
            return (
                <>
                    <div className="contam-chart-bar"  style={getContaminantBarWidth(contaminantItem.ContaminantMCLValue)}></div>
                    <span>{adjustContaminantCalculation(contaminantItem.ContaminantUnits, contaminantItem.ContaminantDisplayUnits, contaminantItem.ContaminantMCLValue)}</span>
                </>
            )
        } else {
            return (
                <>
                    <div className="contam-chart-bar mcl-chart-no-limit flex">NO LEGAL LIMIT</div>
                </>
            )
        }
    }

    function showLegalLimit( contaminantItem ) {
        if ( shouldShowLegalLimit(contaminantItem) ) {
            return (
                <>
                    <span className="detect-levels-item-key">LEGAL LIMIT</span>
                    <span className="detect-levels-item-value">{contaminantItem.ContaminantMCLValue}&nbsp;{contaminantItem.ContaminantDisplayUnits}</span>
                </>
            )
        } else {
            return (
                <>
                    <span className="detect-levels-item-key text-muted">NO LEGAL LIMIT</span>
                </>
            )
        }
    }

    return <>
        <div className={highLightCard(contaminantItemObj.SystemAverage, contaminantItemObj.ContaminantHGValue) + " contaminant-grid-item"}>
            <section className={"contaminant-data"}>
                <div className={"contaminant-data-summary"}>
                    <div className="contaminant-name">
                        <h3>{contaminantItemObj.ContaminantName}</h3>
                        {
                            contaminantItemObj.exceededLimit &&
                            <div>
                                <div className="potentital-effect"> Potential Effect: {contaminantItemObj.ContaminantEffect}</div>
                                <div className="detect-times-greater-than">{getExceedFactor(contaminantItemObj.SystemAverage, contaminantItemObj.ContaminantHGValue)}x</div>
                                <div className="potentital-effect"> EWG'S HEALTH GUIDELINE</div>
                            </div>
                        }
                    </div>
                    <div className="contaminant-data-guidelines">
                        <div className="detect-levels-overview">
                            <div className="detect-levels-item">
                                <span className="detect-levels-item-key">THIS UTILITY</span>
                                <span className="detect-levels-item-value">{adjustContaminantCalculation(contaminantItemObj.ContaminantUnits, contaminantItemObj.ContaminantDisplayUnits, contaminantItemObj.SystemAverage)}</span>
                            </div>
                            <div className="detect-levels-item">
                                <span className="detect-levels-item-key">EWG HEALTH GUIDELINE</span>
                                <span className="detect-levels-item-value">{adjustContaminantCalculation(contaminantItemObj.ContaminantUnits, contaminantItemObj.ContaminantDisplayUnits, contaminantItemObj.ContaminantHGValue)}</span>
                            </div>
                            <div className="detect-levels-item">
                                {showLegalLimit(contaminantItemObj)}
                            </div>
                        </div>
                        <div className="btn-contam-wrapper">
                            <a className="btn-contam-details" onClick={showDetailDisplayMode}>DETAILS</a>
                        </div>
                        
                    </div>
                </div>

                {/* Details div */}
                <div className="contam-modal-wrapper" style={{display: (detailDisplayMode == BLOCK) ? 'block' : 'none' }}>
                    <div className="contam-modal-details">
                        <div className="close-modal" onClick={hideDetailDisplayMode}>X</div>
                        <div className="contam-modal-header-wrapper flex">
                            <h3>{contaminantItemObj.ContaminantName}</h3>
                        </div>
                        <div className="contaminant-description">
                            <p>{contaminantItemObj.ContaminantDescription}</p>
                        </div>

                        <h2 className="levels-compare-h2">
                            {contaminantItemObj.ContaminantName} was found at {getExceedFactor(contaminantItemObj.SystemAverage, contaminantItemObj.ContaminantHGValue)} times above EWG's Health Guideline.
                        </h2>
                        <br/>

                        <div className="contaminant-chart">
                            <p>EWG Health Guideline</p>
                            <div className="contam-bar-wrapper flex">
                                <div className="contam-chart-bar ewg-health-guideline-chart" style={getContaminantBarWidth(contaminantItemObj.ContaminantHGValue)}></div>
                                <span>
                                    {adjustContaminantCalculation(contaminantItemObj.ContaminantUnits, contaminantItemObj.ContaminantDisplayUnits, contaminantItemObj.ContaminantHGValue)} or less
                                </span>
                            </div>

                            <p>This Utility</p>
                            <div className="contam-bar-wrapper flex">
                                <div className="contam-chart-bar this-utility-chart"  style={getContaminantBarWidth(contaminantItemObj.SystemAverage)}></div>
                                <span>{adjustContaminantCalculation(contaminantItemObj.ContaminantUnits, contaminantItemObj.ContaminantDisplayUnits, contaminantItemObj.SystemAverage)}</span>
                            </div>

                            <p>National Average</p>
                            <div className="contam-bar-wrapper flex">
                                <div className="contam-chart-bar"  style={getContaminantBarWidth(contaminantItemObj.ContaminantNationalAvg)}></div>
                                <span>{adjustContaminantCalculation(contaminantItemObj.ContaminantUnits, contaminantItemObj.ContaminantDisplayUnits, contaminantItemObj.ContaminantNationalAvg)}</span>
                            </div>

                            <p>State Average</p>
                            <div className="contam-bar-wrapper flex">
                                <div className="contam-chart-bar"  style={getContaminantBarWidth(contaminantItemObj.ContaminantStateAvg)}></div>
                                <span>{adjustContaminantCalculation(contaminantItemObj.ContaminantUnits, contaminantItemObj.ContaminantDisplayUnits, contaminantItemObj.ContaminantStateAvg)}</span>
                            </div>
                        </div>

                        <div className="contam-bar-wrapper flex">
                            {showLegalLimitBarChart(contaminantItemObj)}
                        </div>

                        <span>The state and national averages were calculated using the averages of the contaminant measurements for each utility in {contaminantItemObj.ContaminantTimeFrame}. </span>
                        <br />
                        <span>ppb = parts per billion</span>

                        <div className="health-risks">
                            <h2>Health Risks</h2>
                            <p>{contaminantItemObj.ContaminantHealthRisks}</p>
                        </div>

                        <div className="pollution-filtering-wrapper flex">
                            <div>
                                <h2>Pollution Sources</h2>
                                <div className="pollution-sources-modal-wrapper flex">
                                    <div className="pollution-source-wrapper">
                                        <img className="pollution-source-img" src="../images/pollution-source-treatment-byproducts.png" alt="water treatment icon" />
                                        <p>Treatment Byproducts</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h2>Filtering Options</h2>

                                <div className="pollution-sources-modal-wrapper flex">
                                    {
                                        (contaminantItemObj.FilterCarbon === 'X') ?
                                        <div className="pollution-source-wrapper">
                                            <img className="pollution-source-img" src="../images/Carbon-Icon.png" alt="carbon filter icon" />
                                            <p>Activated Carbon</p>
                                        </div>
                                        : null
                                    }

                                    {
                                        (contaminantItemObj.FilterRO === 'X') ?
                                        <div className="pollution-source-wrapper">
                                            <img className="pollution-source-img" src="../images/Osmosis-Icon.png" alt="reverse osmosis icon" />
                                            <p>Reverse Osmosis</p>
                                        </div>
                                        : null
                                    }

                                    {
                                        (contaminantItemObj.FilterIX === 'X') ?
                                        <div className="pollution-source-wrapper">
                                            <img className="pollution-source-img" src="../images/IonExchange_Icon.webp" alt="ion exchange icon" />
                                            <p>Ion Exchange</p>
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}