import React from 'react';

export function ModalSpinner( {showSpinner} ) {

    return <>
        {
            // showSpinner && 
            // <div className="modal show fade spinner" style={{display:"block"}} id="myModal">
            //     <div className="modal-body">
            //         <div className="progress">
            //             <div className="progress-bar progress-bar-striped progress-bar-animated" 
            //             role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{"width": "75%"}}></div>
            //         </div>
            //     </div>
            // </div>

            showSpinner && 
            <div className="modal show fade" style={{display:"block"}} id="myModal">
                <div className="modal-body">
                     {/* <div className="progress">
                         <div className="progress-bar progress-bar-striped progress-bar-animated waterProgressBar" 
                         role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{"width": "45%"}}></div>
                     </div> */}
                     <div className="spinnerContainer">
                        <img src="../images/Dripping_Water_Gif.gif" alt="loading..." className="waterDropletProgress"/>  
                     </div>
                </div>
            </div>
        }
        {
            !showSpinner && 
            <></>
        }
    </>
     
}