import React from 'react';

export function FilterAvailable( {contaminantItem, filterType} ) {

    let canFilter = false;
    if ( contaminantItem && contaminantItem[ filterType ] == 'X') {
        canFilter = true;
    }
    

    return (
        <>
            {
                canFilter &&
                <span>&#10003;</span>
            }
            {
                !canFilter &&
                <span>&nbsp;</span>
            }
        </>
    )

    

}