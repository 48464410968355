import React from 'react';

export function WaterHeader() {

    return <div className="header">
        <div className="row">
            <div className="col-12 nopadding">
                <div className="">
                    <img src="../images/Metallic_Background-With_Logo.jpg" className="headerWithLogo"/>
                </div>
            </div>
        </div>
    </div>
}