import React, {useState} from 'react';
import {getExceededContaminantsList, getOtherContaminantsList} from '../utils/water.data.utils';
import {FilterAvailable} from './filter.available';

export function WaterFilterTable( {contaminantsList} ) {


    let exceededContaminantsList = [],  otherContaminantsList = []
    

    if ( contaminantsList ) {
        exceededContaminantsList = getExceededContaminantsList(contaminantsList);
        otherContaminantsList = getOtherContaminantsList(contaminantsList);
    }
    
    // const [exceededContaminantsList, setExceededContaminantsList] = useState(getExceededContaminantsList(contaminantsList));
    // const [otherContaminantsList, setOtherContaminantsList] = useState(getOtherContaminantsList(contaminantsList));


    function canFilter(eachContaminant, filterType) {
        if ( eachContaminant && eachContaminant[ filterType ] == 'X') {
            return true;
        }
        return false;

        
    }

    function displayContaminantFilterRows( selectedContaminantList ) {
        if ( selectedContaminantList ) {
            return selectedContaminantList.map( (eachContaminant, index) => {
                return (
                    <tr key={index}>
                        <td className="table-first-col">{eachContaminant.ContaminantName}</td>
                        <td className="filterTableCell"><FilterAvailable contaminantItem={eachContaminant} filterType={'FilterCarbon'}/></td>
                        <td className="filterTableCell"><FilterAvailable contaminantItem={eachContaminant} filterType={'FilterRO'}/></td>
                        <td className="filterTableCell"><FilterAvailable contaminantItem={eachContaminant} filterType={'FilterIX'}/></td>
                    </tr>
                )
            })
        }
        return <></>
    }

    return <div className="filter-table-wrapper">
        <h2 className="blueText1 textBold">Water Filters That Can Reduce Contaminant Levels</h2>
        <table className="filter-table table-striped">
            <thead>
                <tr>
                    <td className="table-first-col">Contaminant</td>
                    <td>
                        <img className="carbon mobile-hide-md" src="../images/table-icon-activated-carbon.svg"
                         alt="activated carbon"/>
                    </td>
                    <td>
                        <img className="ro mobile-hide-md" src="../images/table-icon-reverse-osmosis.svg" 
                        alt="reverse osmosis"/>
                    </td>
                    <td>
                        <img className="ion mobile-hide-md" src="../images/table-icon-ion-exchange.svg" 
                        alt="ion exchange"/>
                    </td>
                </tr>
            </thead>
            
            <tbody>
                <tr className="filter-table-subheader">
                    <td colSpan="4">CONTAMINANTS ABOVE<br/>HEALTH GUIDELINES</td>
                </tr>
                { displayContaminantFilterRows(exceededContaminantsList) }
                
                <tr className="filter-table-subheader">
                    <td colSpan="4">OTHER CONTAMINANTS<br/>DETECTED</td>
                </tr>
                <tr></tr>
                { displayContaminantFilterRows(otherContaminantsList) }
            </tbody>
        </table>
    </div>
}