import React from 'react';
import { useHistory } from "react-router-dom";


export function FacilitiesTable( {facilitiesList, zipCode} ) {

    const history = useHistory();

    // function getContaminantsPageHref(facility) {
    //     return `/contaminantsList?pws=${facility.PWS}&zipCode=${zipCode}`;
    // }


    function getFormattedPopulation(value) {
        if ( value ) {
            return value.toLocaleString(navigator.language, { minimumFractionDigits: 0 });    
        } 
        return 0;
        
    }

    function goToContaminants(event, facility) {
        event.preventDefault();
        history.push(`/contaminantsList?pws=${facility.PWS}&zipCode=${zipCode}`);
    }

    return (
        <div className="search-results-figure flex facilitiesTableContainer">
            {
                facilitiesList && facilitiesList.length > 0 &&
                <table className="search-results-table">
                    <thead>
                        <tr>
                            <th>Utility name</th>
                            <th className="text-right">City</th>
                            <th className="text-right">People served</th>
                        </tr>
                    </thead>
                    <tbody> 
                        {
                            facilitiesList.map( (facility, index) => {
                                return <tr key={index}>
                                    <td><a className="blueText1 textBold" onClick={(event) => goToContaminants(event, facility)} href="#">{facility.SystemName}</a></td>
                                    <td className="text-right blueText2">{facility.CityServed}</td>
                                    <td className="text-right blueText2"><span className="pop-served-mobile"></span> {getFormattedPopulation(facility.Population)}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            }
        </div>
    )

}