import React, {useState} from 'react';
import { useHistory } from "react-router-dom";

export function ZipInput() {

    const [zipCode, setZipCode] = useState();
    const history = useHistory();

    function goToResults(event) {
        
        let zipCodeLength = zipCode.length;
        if (zipCodeLength === 5) {
            event.preventDefault();
            history.push("/facilitiesList?zipCode=" + zipCode);
        }
    }

    function handleKeypress(event) {
        if (event.code === 'Enter' || event.charCode === 13) {
            goToResults(event);
        }
    };

    function handleZipCodeOnChange(event) {
        const { name, value } = event.target;
        const numberValue = value.replace(/\D/g, "");
        setZipCode(numberValue);    
    }

    return <>
        <input type="text" name="zipCode" placeholder="Enter your ZIP code" 
                        maxLength="5" minLength="5"
                        onChange={handleZipCodeOnChange} 
                        onKeyPress={handleKeypress}
                        className="zipInput"
                        value={zipCode}
                        autoComplete="off"/>
        <a href="/#" onClick={goToResults} className="goButton">GO</a>
    </>
}