import './css/water-kiosk.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import './scss/water-kiosk.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  HashRouter
} from "react-router-dom";

import {SearchZip} from './searchZip/search.zip';
import {FacilitiesList} from './facilitiesList/facilities.list';
import {ContaminantsList} from './contaminantsList/contaminants.list.js';

function App() {
  return (
    <HashRouter>
      <div className='waterKioskRouterContainer'> 
        <Switch>
          <Route exact path="/">
            <SearchZip />
          </Route>
          <Route path="/facilitiesList">
            <FacilitiesList />
          </Route>
          <Route path="/contaminantsList">
            <ContaminantsList />
          </Route>
          <Route path="/*">
            <SearchZip />
          </Route>
        </Switch>
      </div>
    </HashRouter>
    
  );
}

export default App;
