var moment = require('moment-timezone');


export function getApiStartDate() {
    return moment().subtract(3, 'years').format('MM/YYYY');
}

export function getApiEndDate() {
    return moment().format('MM/YYYY');
}

export function getApiYear() {
    return moment().subtract(3, 'years').format('YYYY');
}

export function getStringMonthAndYearForDate(dateString) {
    return moment(dateString).format('MMMM YYYY');
}

export function getStringMonthAndYearForCurrentDate() {
    return moment().format('MMMM YYYY');
}