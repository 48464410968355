import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router';
import {getContaminantsByFacilityId } from '../service/ewg.data.service';
import queryString from 'query-string';
import {getZipCodesConfig, getStateCodeForZip} from '../utils/zip.utils';
import {WaterFooter} from "../common/WaterFooter";
import {WaterHeader} from "../common/WaterHeader";
import {WhatsInYourWater} from "../common/WhatsInYourWater";
import {ModalSpinner} from "../common/ModalSpinner";
import {getContaminantsCount, getExceededContaminantsCount} from '../utils/water.data.utils';
import {getStringMonthAndYearForDate, getStringMonthAndYearForCurrentDate} from '../utils/date.utils';
import {ContaminantsGrid} from './contaminants.grid';
import {WaterFilterTable} from './water.filter.table';
import {redirectToHome} from '../utils/redirect.utils';

export function ContaminantsList() {

    const location = useLocation();
    const[facilityContaminantsList, setFacilityContaminantsList] = useState();
    const[exceedContaminants, setExceedContaminants] = useState();
    const[totalContaminants, setTotalContaminants] = useState();
    const[facilityContaminantsError, setFacilityContaminantsError] = useState(false);
    const[cityName, setCityName] = useState();
    const[stateName, setStateName] = useState();
    const[loading, setLoading] = useState(false);
    
    const[startEffectiveDate, setStartEffectiveDate] = useState();
    const[endEffectiveDate, setEndEffectiveDate] = useState();

    const zipCodesConfig = getZipCodesConfig();

    const queryStringParams = queryString.parse(location.search);
    const searchedZipCode = queryStringParams.zipCode;
    const selectedFacilityId = queryStringParams.pws;

    
    useEffect( () => {

        setFacilityContaminantsError(false);

        if ( zipCodesConfig[searchedZipCode] ) {
            setCityName(zipCodesConfig[searchedZipCode].city);
            setStateName(zipCodesConfig[searchedZipCode].state);    
        }

        setLoading(true);

        getContaminantsByFacilityId(selectedFacilityId)
        .then( response => handleFacilityContaminantsSuccess(response) )
        .catch( error => handleFacilityContaminantsError(error) );


        window.setTimeout(()=> {
            redirectToHome(window)
          }, 180000);

    }, [ selectedFacilityId ]);

    useEffect( () => {
        if ( facilityContaminantsList ) {
            
            setTotalContaminants(getContaminantsCount(facilityContaminantsList));
            setExceedContaminants(getExceededContaminantsCount(facilityContaminantsList));
        }

    }, [facilityContaminantsList, selectedFacilityId] )

    function handleFacilityContaminantsSuccess( response ) {
        
        setLoading(false)
        
        if ( response && response.data ) {
            let apiData = response.data;
            setFacilityContaminantsList(apiData.ContaminantList)
        } else {
            handleFacilityContaminantsError({data: 'no data returned'});
        }
    }

    function handleFacilityContaminantsError(error) {
        //console.log(error);
        setLoading(false);
        setFacilityContaminantsError(true);
   }

   
    return <>
        <div className="container-fluid">
            <ModalSpinner showSpinner={loading}/>
            <div>
                <WaterHeader></WaterHeader>
            </div>
            <div className="contaminantListContainer">
                <div className="row text-center">
                    <div className="col-lg-12">
                        <WhatsInYourWater/>
                    </div>
                </div>
                <div className="row">
                    <div>&nbsp;</div>
                </div>
                <div className="row">
                    <div className="pl-5 pr-5">
                        <div className="row">
                            {
                                !facilityContaminantsError && 
                                <span className="blueHeader2">Zip Code {searchedZipCode} {cityName} - {stateName}</span>
                            }
                            {
                                facilityContaminantsError && 
                                <span className="blueHeader2">Displaying results typical of Metro Atlanta</span>
                            }
                            
                        </div>
                        <div className="row">
                            <span className="infoText">This water quality reports shows information from the U.S. EPA Enforcement and Compliance History database (ECHO). 
                                For the time period assessed by the U.S. EPA 
                                (
                                    {
                                        startEffectiveDate && endEffectiveDate &&
                                        <span>{getStringMonthAndYearForDate(startEffectiveDate)} - {getStringMonthAndYearForDate(endEffectiveDate)}</span>
                                    }
                                    {
                                        !startEffectiveDate && !endEffectiveDate && 
                                        <span>as of {getStringMonthAndYearForCurrentDate()}</span> 
                                    }
                                )
                                tap water provided by this water utility was 
                                in compliance with federal health-based drinking water standards.
                            </span>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="row">
                            <span className="blueHeader1">Contaminants Detected</span>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div className="contaminantsCircle text-center">
                                    <span className="blueText2 textBold">{exceedContaminants}</span> 
                                    <div className="blueText1 textBold contaminantsDetected">Exceed Health Guidelines</div>
                                    <div>&nbsp;</div>
                                </div>
                                <div> &nbsp; </div>
                                <div className="totalContaminants text-center">
                                    <span className="blueText1 textBold">{totalContaminants} Total Contaminants</span>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-7 infoText2 contaminantsLegal">
                                <div>&nbsp;</div>
                            
                                <div>
                                    <ul>
                                        <li>Legal does not necessarily equal safe. Getting a passing grade from the federal government does not mean the water meets the latest health guidelines.</li>
                                        <li>Legal limits for contaminants in tap water have not been updated in almost 20 years.</li>
                                        <li className="textBold">One way to ensure clean tap water is to filter your tap water.</li>
                                    </ul>
                                </div>
                                
                                <div>&nbsp;</div>
                                
                                <div className="text-center">
                                    <a href="https://www.juturnakiosk.com/contact/">
                                        <img src="../images/water-tested_button.png" alt="Get your water tested" className="getYourWaterTestedImg"/>
                                    
                                        <div>
                                            <span className="blueText1 textBold blockElem">Click here to schedule</span>
                                            <span className="blueText1 textBold blockElem">your free water</span>
                                            <span className="blueText1 textBold blockElem">contaminants test</span>
                                      </div>
                                    </a>
                                </div>

                            </div>
                        </div>

                        {
                          facilityContaminantsList && 
                          <>
                            <ContaminantsGrid contaminantsList={facilityContaminantsList}/>  
                            <div>&nbsp;</div>
                            <WaterFilterTable contaminantsList={facilityContaminantsList}/>   
                          </>
                        }
                        
                        {/* <embed type="application/xhtml+xml"
                                src="https://waterapi.ewg.org/systeminformation.php?pws=GA1210001&html=X&key=7b1229e3-1333-b232-3681-44ebb7b5a886"
                                width="1000"
                                height="5000"></embed> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <WaterFooter showNowWhat={true}/>
                    </div>
                </div>
            </div>
        </div>

        
    </>
}
