

import React from 'react';

export function FooterLinks(  ) {

    return <div className="footerLinks">
        <div className="flex footerLinksContainer">
            <div className="footerLink"><a href="https://www.juturnakiosk.com/introduction/">Home</a></div>
            <div className="footerLink"><a href="https://juturnakioskcontaminates.com/">City Water</a></div>
            <div className="footerLink"><a href="https://www.juturnakiosk.com/well-water/">Well Water</a></div>
            <div className="footerLink"><a href="https://www.juturnakiosk.com/faqs/">FAQ</a></div>
            <div className="footerLink"><a href="https://www.juturnakiosk.com/product-info/">Product Info</a></div>
            <div className="footerLink"><a href="https://www.juturnakiosk.com/contact/">Contact</a>&nbsp;&nbsp;</div>
        </div>
    </div>
    
}