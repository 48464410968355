import React from 'react';
import {ContaminantItem} from './contaminants.item';
import {getExceededContaminantsList, getOtherContaminantsList} from '../utils/water.data.utils'

const cardsPerRow = 3;

export function ContaminantsGrid( {contaminantsList} ) {

    let exceededContaminantsList = getExceededContaminantsList(contaminantsList);
    let otherContaminantsList = getOtherContaminantsList(contaminantsList);


    /**
     * put 3 cards of each row in an arrayList
     * 
     * @param {*} newContaminantsList 
     */
    function displayCotaminantsCards( newContaminantsList ) {
        
        if ( newContaminantsList && newContaminantsList.length > 0 ) {
            let contaminantCardsByRowList = [];
            let cardIndex = 0
            do {
                contaminantCardsByRowList.push(getOneRowOfCards(newContaminantsList, cardIndex))

                cardIndex = cardIndex + cardsPerRow;
            } while (cardIndex < newContaminantsList.length ) 

            return contaminantCardsByRowList;
        }
        return null;
    }

    /**
     * Display 3 cards per row
     * 
     * @param {*} newContaminantsList 
     * @param {*} startIndex 
     * @returns 
     */
    function getOneRowOfCards( newContaminantsList, startIndex ) {
        return <div key={startIndex}>
            {
                newContaminantsList[startIndex] && 
                <div className="row">
                    {
                        newContaminantsList[startIndex] && 
                        <div className="col-sm-12 col-md-4">
                            <ContaminantItem contaminantItemObj={newContaminantsList[startIndex]}/>
                        </div>
                    }
                    {
                        newContaminantsList[startIndex + 1] && 
                        <div className="col-sm-12 col-md-4">
                            <ContaminantItem contaminantItemObj={newContaminantsList[startIndex + 1]}/>
                        </div>
                    }
                    {
                        newContaminantsList[startIndex + 2] && 
                        <div className="col-sm-12 col-md-4">
                            <ContaminantItem  contaminantItemObj={newContaminantsList[startIndex + 2]}/>
                        </div>
                    }
                </div>
            }
        </div>
    }

    return <>
        <div className="contaminantType">
            <span className="contaminantTypeLabel font-weight-bold">Exceed Guidelines</span>
        </div>
        {displayCotaminantsCards(exceededContaminantsList)}
        <div className="contaminantType">
            <span className="contaminantTypeLabel font-weight-bold">Other Detected</span>
        </div>
        {displayCotaminantsCards(otherContaminantsList)}
        <div>&nbsp;</div>
    </>
}
