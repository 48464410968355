import axios from 'axios';
import queryString from 'query-string';
import {enrichContaminantsByFacilityIdResponse} from '../utils/water.data.utils';

const endPointDomain = 'https://waterapi.ewg.org';
const apiKey = '7b1229e3-1333-b232-3681-44ebb7b5a886';

/**
 * Get all the facilities servicing a ZipCode
 * @param  zipCode 
 * @returns 
 */
export function searchFacilitiesByZipCode( zipCode ) {

    let params = {
        zip: zipCode,
        allCounty: 'X',
        key: apiKey
    }

    let baseEndPoint = `${endPointDomain}/zip_systems.php`;
    let endPoint = queryString.stringifyUrl({ url: baseEndPoint, query: params});

    return axios.get(endPoint);
} 

/**
 * Get all the contaminants by facilityId
 * 
 * @param {*} facilityId 
 * @returns 
 */
export function getContaminantsByFacilityId( facilityId ) {
    let params = {
        pws: facilityId,
        key: apiKey
    }

    let baseEndPoint = `${endPointDomain}/systeminformation.php`;
    let endPoint = queryString.stringifyUrl({ url: baseEndPoint, query: params});

    let apiPromise = axios.get(endPoint);
    return apiPromise.then( (rawResponse) => {
        let enrichedResponse = enrichContaminantsByFacilityIdResponse(rawResponse);
        return new Promise( (resolve, reject) => {
            resolve(enrichedResponse)
        });
    
    }).catch( (error) => {
        return new Promise( (resolve, reject) => {
            reject(error.data)
        });
    });
    

    


}

/**
 * Get all the contaminants by ZipCode
 * 
 * @param {*} zipCode 
 * @returns 
 */
export function getContaminantsByZipCode( zipCode ) {
    let params = {
        zip: zipCode,
        allCounty: 'X',
        key: apiKey
    }

    let baseEndPoint = `${endPointDomain}/zip_contaminant.php`;
    let endPoint = queryString.stringifyUrl({ url: baseEndPoint, query: params});

    return axios.get(endPoint);
}

export function geContaminantsHTMLByFacilityId( facilityId ) {
    let params = {
        pws: 'GA1210001',
        key: apiKey,
        html: 'X'
    }

    let baseEndPoint = `${endPointDomain}/zip_contaminant.php`;
    let endPoint = queryString.stringifyUrl({ url: baseEndPoint, query: params});

    //return axios.get(endPoint);

    return axios({
        method: 'get',
        url: endPoint,
        responseType: 'stream'
      })
}



