import React from 'react';
import {FooterLinks} from './FooterLinks';

export function WaterFooter( {showNowWhat} )  {
    return <div className="footer">
        <div className="row">
            <div className="col-12 nopadding">
                <div className="lowesLogo">
                    {
                        showNowWhat && 
                        <div className="nowWhatBox">
                            <a href="https://www.juturnakiosk.com/city-water-2/">
                            <img src="../images/Now_What-Button.png" alt="Now What" className="nowWhatImage"/>
                            </a>
                        </div>
                    }
                    
                </div>
                
                <FooterLinks/>
            </div>
        </div>
        
    </div>
}