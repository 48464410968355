import React, {useState} from 'react';
import {WaterFooter} from "../common/WaterFooter";
import {WaterHeader} from "../common/WaterHeader";
import {WhatsInYourWater} from "../common/WhatsInYourWater";
import {ZipInput} from './zip.input';



export function SearchZip() {

    return <div className="container-fluid">
        <div>
            <WaterHeader></WaterHeader>
        </div>
        <div className="searchPageSpacer">&nbsp;</div>
        <div className="searchZipCodeContainer">
            <div className="row">
                <div className="col mb-2">
                    <WhatsInYourWater/>
                </div>
            </div>
            <div className="row">
                <div className="col mb-2">
                    <span className="infoText textBold">
                        Enter your zip code below to instantly see what contaminants have <br/>been detected in the drinking water in your area.
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ZipInput/>
                </div>
            </div>
        </div>
        <WaterFooter></WaterFooter>
    </div>;
}